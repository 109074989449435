.map-sidebar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  padding-top: 2rem;
}

.map-sidebar--content {
  height: 100%;
  background-color: #fff;
  padding: 1.5rem 0.6rem;
  border-top: 2px solid #0b0c0c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 650;
  /* text-align: center; */
  word-wrap: break-word;
  word-break: normal;
  overflow: auto;
}

.map-sidebar--content ul {
  padding-left: 1rem;
}

.map-sidebar--content li {
  margin-bottom: .5rem;
}

.map-sidebar--toggle {
  width: 4rem;
  height: 2rem;
  position: absolute;
  left: 50%;
  bottom: 100%;
  margin-left: -2rem;
  margin-bottom: -2rem;
  z-index: 9;
  border: 2px solid black;
  border-bottom: 0;
}

.map-sidebar--toggle-text {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}



@media (max-width: 49.9375em) {
  .map-sidebar.closed {
    bottom: -50%;
    margin-bottom: 2rem;
  }

  .icon-open-close.icon-open {
    transform: rotate(270deg);
  }
  
  .icon-open-close.icon-close {
    transform: rotate(90deg);
  }

}

@media (min-width: 50em) {
  .map-sidebar {
    top: 0rem;
    height: auto;
    width: 20rem;
    padding-top: 0;
    padding-right: 2rem;
  }

  .map-sidebar.closed {
    left: -20rem;
    margin-left: 2rem;
  }

  .map-sidebar--content {
    border-top: 0;
    border-right: 2px solid #0b0c0c;
  }

  .map-sidebar--toggle {
    width: 2rem;
    height: 4rem;
    left: 100%;
    bottom: 50%;
    margin-bottom: -2rem;
    border: 2px solid black;
    border-left: 0;
  }

  /* don't need to rotate. default is correct */
  /* .icon-open-close.icon-open {} */
  
  .icon-open-close.icon-close {
    transform: rotate(180deg);
  }
}