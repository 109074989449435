body._page-Map .App-main {
  overflow: hidden;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.map-infobox {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 0.4rem 0.65rem;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2.6rem;
  margin-bottom: .4rem;
  border-radius: 0.4rem;
}
