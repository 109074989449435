.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #241023;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
}

.App-name {
  font-size: 2rem;
  margin: 1rem auto 1rem 0;
  color: white;
}

.App-main {
  flex-grow: 1;
  position: relative;
  padding: .01px;
}
